.about-wrapper {
    background-color: $light-blue;
    min-height: 600px;
    background-color: #96b5ba;
    background-color: #96b5ba;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.46'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    padding: 50px 0;
    background-size: 2%;
    overflow: hidden;

    .about {
        height: 100%;

        .about-data {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 15px;
            padding: 20px 0;

            * {
                @include for-tablet-layout {
                    flex-direction: column;
                }

            }

            .about-text {
                display: flex;
                background-color: $white;
                padding: 20px;
                border-radius: $in-radius;
                box-shadow: 0px 0px 10px -2px rgba(76, 76, 76, 0.342);
                align-items: center;

                ul {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    li {
                        margin: 3px 4px;
                        padding-right: 5px;
                        list-style-type: "✤";

                        &::marker {
                            color: $blue;
                            font-size: 20px;
                        }

                        p {
                            color: $black;
                            font-size: 20px;
                        }
                    }

                    button {
                        justify-self: center;
                        align-self: center;
                        margin-top: 10px;
                        padding: 5px 20px;

                        a {
                            justify-self: center;
                            align-self: center;
                            font-size: 20px;
                            color: $white ;

                            &:visited {
                                color: $white ;
                            }
                        }
                    }

                }

                &.cert {
                    ul {
                        justify-self: center;
                        align-self: center;
                        width: fit-content;

                        li {
                            width: 100%;
                        }
                    }
                }
            }

            img {
                aspect-ratio: 1/1;
                padding: 10px;
                display: flex;
                object-fit: cover;
                object-position: 35%;
                width: 100%;
                max-width: 365px;
                background-color: $green;
                border-radius: 50%;
                align-self: center;
            }
        }
    }

}