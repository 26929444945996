.recommendations {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 40px;
    .recommendations-carousel {
        background-image: url('../../images/reccomendations.jpg');
        background-position: center;
        background-size: cover;
        width: 100%;
        max-width: 80vw;

        @include for-tablet-layout {

            p {
                font-size: $small;
            }
        }

        .splide {
            width: 100%;

            .splide__track {
                width: 100%;
            }

        }

        .splide__arrow {
            // border-radius: 50%;
            background-color: $white;
            transform: translate(0, 0);
            bottom: 0;
            transform: translateY(calc(100% + 50px));
            @include for-mobile-layout{
                font-size: $small;
            }
        }

        .splide__pagination__page {
            padding: 2px 10px;
            background-color: grey;

            &.is-active {
                background-color: $white;
            }
        }

        .splide__arrow--prev {
            margin-right: -15px;
        }

        .splide__arrow--next {
            margin-left: -15px;

        }
    }

    .recommendation {
        // background-image: url('../../images/reccomendations.jpg');
        background-position: center;
        background-size: cover;
        padding: 100px 10px;

        @include for-tablet-layout {
            padding: 50px 10px;

        }


        .text-wrapper {
            width: 50%;
            background-image: url('../../images/notepad\ page.svg');
            background-position: 0% 20%;
            background-size: cover;
            background-repeat: no-repeat;
            transform: scaleX(-1);
            // background-repeat: no-repeat;
            padding: 20px 20px 20px 120px;
            min-width: 200px;

            @include for-tablet-layout {
                width: 100%;
                padding: 20px 20px 20px 60px;

            }

            .text {
                transform: scaleX(-1);
                width: 100%;
            }
        }

        p {
            color: $black;
            font-size: $small + 2;
            line-height: $h2;
            font-weight: 700;

            @include for-tablet-layout {
                line-height: $p;
            }
        }

        .recommender {

            text-align: left;
            padding: 20px 0 0 20px;
            font-weight: 700;
            font-size: $p;
        }
    }
}