.treatments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 50px 0px 0px;
    img {
        width: 100%;
        max-width: 600px;
        padding-top: 70px;

        @include for-tablet-layout {
            padding: 0;
        }

    }

    .dropdowns {
        display: flex;
        justify-content: center;
        gap: 20px;

        @include for-tablet-layout {
            flex-direction: column;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            width: 100%;
            margin: auto;
        }

        .dropdown {
            width: 100%;
            border-radius: $br;
            padding: 10px 20px;
            border: 3px solid $blue;
            background-color: $white;
            transition: 0.3s;
            justify-self: center;

            div {
                width: 100%;
                // max-height: 130px;
                text-align: center;
            }

            ul {
                visibility: hidden;
                height: 0;
                opacity: 0;

                @include for-tablet-layout {
                    visibility: visible;
                    height: 100%;
                    opacity: 1;
                }

                li {
                    margin: 0 5px 0 0;
                }

                p {
                    font-size: $small;
                    line-height: $small;
                }
            }

            &.fire,
            &.earth,
            &.metal,
            &.water,
            &.wood {
                position: absolute;
                max-width: 250px;
                width: fit-content;
                box-shadow: 0px 5px 10px -2px rgba(76, 76, 76, 0.342);

                @include for-tablet-layout {
                    max-width: 100%;
                    width: 100%;

                }

                &:hover {
                    z-index: 5;
                    transform: translateY(-10%);

                    ul {
                        opacity: 1;
                        transition: 0.5s;
                        visibility: visible;
                        height: 100%;
                    }
                }

                @include for-tablet-layout {
                    position: static;

                    &:hover {
                        transform: translateY(0);
                    }
                }

            }

            &.fire {
                top: -2%;
                // background-color: white;
            }

            &.earth {
                top: 30%;
                right: 3%;
            }

            &.metal {
                top: 70%;
                right: 10%;
            }

            &.water {
                top: 70%;
                left: 10%;
            }

            &.wood {
                top: 30%;
                left: 3%;
            }

            &.metal,
            &.water {

                &:hover {
                    transform: translateY(-50%);
                }

                @include for-tablet-layout {
                    &:hover {
                        transform: translateY(0);
                    }
                }

            }
        }
    }
}