// COLORS
$blue: #518691;
$light-blue: #96b5ba;
$bluer: #3e5862;
$green: #bcdeae;
$white: #fffce3;
$white2: rgb(239, 237, 213);
$grey: #e4e4e4ff;
$black: #202020ff;
$greyer: rgb(58, 58, 58);
$grey2: rgb(150, 150, 150);

// LAYOUT
$layoutPadding: 20px;
$padding: 10px;

// BREAKPOINTS
$mobile-breakpoint: 460px;
$tablet-breakpoint: 800px;
$desktop-breakpoint: 960px;
$wide-dekstop-breakpoint: 1200px;

// VARS

// TYPOGRAPHY
$smaller: 16px;
$small: 18px;
$p: 24px;
$h1: 60px;
$h2: 38px;
$h3: 30px;
$h4: 24px;
$btn-text: 24px;
$in-radius: 12px;
$br: 12px;