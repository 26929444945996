.footer-wrapper {
    background-image: url('../../images/footer-bg.svg');
    background-repeat: no-repeat;
    background-size: 250%;
    background-position: 60% 80%;
    padding: 20px 0px;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.205);

    @include for-tablet-layout {
        background-position: 100% 100%;

        background-size: 400%;

    }

    .footer {
        b {
            padding: 0 !important;
        }

        .footer-data {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include for-tablet-layout {
                flex-direction: column;
            }

            :not(span, .dictionary) {
                padding: 0 10px;
            }

            img {
                width: 100%;
                max-width: 300px;
            }


            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                justify-content: space-evenly;


                button {
                    all: unset;
                    cursor: pointer;
                    text-align: center;

                    &:hover {
                        text-decoration: underline $black 1.5px;
                        text-underline-offset: 2px;
                    }
                }
            }

            .contact-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                p {
                    font-size: $small;
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: space-around !important;
                text-align: center;
                width: 20%;
                gap: 10px;

                @include for-tablet-layout {
                    width: 100%;
                }

                p {
                    font-size: $small;
                }

                .copyright {
                    // margin-top: 10px;

                    p {
                        font-size: $smaller;
                        color: $grey2;
                    }
                }
            }

            .dictionary {
                flex-direction: column;
                align-items: start;
                justify-content: center;
                margin: 0 30px;

                li {
                    margin: 0;
                    max-width: 300px;
                    list-style-type: disc;
                }

                p {
                    font-size: $smaller;
                }
            }
        }




    }

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;

        p {
            font-family: Amatic-B;
            color: $blue;
            line-height: 39px;
            letter-spacing: 1px;
            font-size: 35px;
        }

        p:first-child {
            font-size: 55px;
        }

        p:last-child {
            letter-spacing: -0.7px;
            font-family: OpenSans;
            font-weight: 500;
            font-size: 20px;
        }

        .logo-img {
            img {
                width: 100%;
                max-width: 200px;
                // filter: hue-rotate(330deg) saturate(70%);
                // transform: rotate(75deg);
                scale: 1.2;
            }
        }
    }
}


ul {
    &.nav-buttons {
        @include for-tablet-layout {
            flex-direction: row !important;
            flex-wrap: wrap;
        }
    }
}