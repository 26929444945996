.treatment-types {
    width: 100%;
    height: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    margin: 100px 0 0 0;

    .test {}

    .title {
        font-size: 60px;
        text-align: center;
    }

    .details {
        transition: 0.3s;
        overflow-x: hidden;

        .diagnosis {
            ol {
                display: grid;
                flex-direction: column;
                grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
                gap: 20px;

                @include for-mobile-layout {
                    grid-template-columns: 1fr;
                    padding: 0;
                }
            }

            li {
                list-style-type: "✤";
            }

            .pulse {
                grid-column: 1 / -1;

                img {
                    object-fit: scale-down;
                    aspect-ratio: auto;
                }
            }

            img {
                width: 100%;
                border-radius: $br;
                filter: brightness(1.25);
                aspect-ratio: 1/1;
            }

        }

        .herbal {
            display: grid;
            flex-direction: column;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            gap: 20px;

            img {
                width: 100%;
                aspect-ratio: 1/1;

                @include for-mobile-layout {
                    aspect-ratio: auto;
                }

                object-fit: cover;
                border-radius: $br + 10px;
            }
        }

        details {
            background-color: $blue;
            border-radius: 20px;
        }

        .details-open-text {
            box-shadow: 0px 5px 10px -2px rgba(76, 76, 76, 0.342);
            border-top: none;
            border-radius: $br;
            padding: 40px 80px;
            margin: 0 20px 20px 20px;
            background-color: $white;
            color: $black;
            font-weight: 600;

            h3 {
                margin-bottom: 10px;
            }


            @include for-tablet-layout {
                padding: 10px 20px;

            }
        }

        .table {
            margin-top: 20px;
            width: 100%;
            border: 2px black solid;
            flex-wrap: wrap;

            @include for-tablet-layout {
                .flex {
                    flex-wrap: wrap;
                }
            }

            .taste {
                width: 100%;
                border: 2px black solid;
                padding: $padding;
                text-align: center;
            }
        }

        .text-small {
            margin-top: 10px;
            font-size: $small;
            font-weight: 700;
        }

        summary {
            outline: none;
            width: 100%;
            font-size: $h2;
            padding: 20px;
            display: block;
            color: $white;
            padding-right: 2.5rem;
            position: relative;
            cursor: pointer;
            font-weight: 700;
        }

        details summary::-webkit-details-marker {
            display: none;
        }

        details[open]>summary:before {
            transform: rotate(90deg);
        }

        summary:before {
            content: '';
            border-width: .6rem;
            border-style: solid;
            border-color: transparent transparent transparent $green;
            position: absolute;
            top: 40%;
            right: 0.5%;
            transform: rotate(180deg);
            transform-origin: .2rem 50%;
            transition: .25s transform ease;
        }

        .content {
            border-top: none;
            padding: 10px;
            border: 2px solid #888;
            border-top: none;
        }

        p {
            margin: 0;
            padding-bottom: 10px;
            font-weight: 500;

            span {
                font-size: $small;
            }

            &:last-child {
                padding: 0;
            }
        }
    }

    .treatment-list {
        ol {

            li {
                list-style: decimal;
            }

            ol {
                li {
                    list-style: disc;
                }
            }
        }
    }

    .cards {
        width: 100%;
        justify-content: space-between;
        gap: 40px;
        z-index: -1;

        .card {
            aspect-ratio: 2/1;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            // width: 100%;
            height: 100%;
            padding: 0 20px;
            text-align: center;
            box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 5px 2px;
            border-radius: $in-radius;
        }
    }
}