* {
    box-sizing: border-box;
}

html {
    transition: 0.2s;
    background-color: $white;
    color: $black;
    direction: rtl;
    font-family: Rubik;
    scroll-behavior: smooth;

}

body {
    margin: 0;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    font-display: block;

    ::selection {
        color: $white;
        background: $green;
    }
}

p,
a {
    font-size: $p;
}

h1 {
    font-size: $h1;
}

h2 {
    font-size: $h2;
}

h3 {
    font-size: $h3;
}

h4 {
    font-size: $h4;
}

a {
    text-decoration: none;

    &:visited {
        color: inherit;
    }
}

@include for-tablet-layout {

    .divider {
        height: 1px !important;
        width: 100% !important;
        margin: 10px 0;
    }
}

p,
label {
    font-size: $p;
}

p,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

li {
    list-style: none;
}

ol {
    li {
        margin: 3px 4px;
        padding-right: 5px;
        list-style: decimal;
        list-style-type: hebrew;

        &::marker {
            color: $blue;
            font-size: 20px;
        }
    }
}

ul {
    padding: 0;
    margin: 0;

    li {
        margin: 3px 4px;
        padding-right: 5px;
        list-style-type: "✤";

        &::marker {
            color: $blue;
            font-size: 20px;
        }
    }
}

ol .li-asterisk,
ul .li-asterisk {
    li {
        list-style-type: "✤" !important;
    }
}

ol,
ul {
    &.no-li-style {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
        }
    }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $blue;
}

::-webkit-scrollbar-thumb:hover {
    background: $bluer;
}

button {
    all: unset;
}

button:not(.logo, .hamburger, .waze, .splide) {
    background-color: $blue;
    font-weight: 700;
    padding: 10px 20px;
    font-size: $btn-text;
    border-radius: 12px;
    box-shadow: rgba(237, 237, 237, 0.2) 5px 5px 20px 0px inset, rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transition: 0.2s;
    width: fit-content;
    color: $white;

    &:hover {
        transform: scale(1.1);
    }

    &:active {
        box-shadow: rgba(99, 99, 99, 0.2) 5px 5px 20px 2px inset, rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

}

.divider {
    width: 1.5px;
    height: 100%;
    background-color: $green;
}