.header-wrapper {
    border-bottom: 2px $grey solid;
    padding: 10px 0px;
    transition: 0.3s;
    position: sticky;
    top: 0;
    background-color: $white;
    width: 100%;
    z-index: 20;

    nav {
        align-items: center;
    }

    .logo {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 5px;
        gap: 6px;
        word-break: keep-all;
        text-wrap: nowrap;

        p {
            font-family: Amatic-B;
            color: $blue;
            line-height: 39px;
            letter-spacing: 1px;
            font-size: 35px;
        }

        p:first-child {
            font-size: 55px;
        }

        p:last-child {
            letter-spacing: -0.7px;
            font-family: OpenSans;
            font-weight: 500;
            font-size: 20px;
        }

        .logo-img {
            img {
                width: 100%;
                max-width: 80px;
                min-width: 50px;
                scale: 1.2;
                margin-top: -12px;
            }

            &::after {
                content: "";
                // background-color: #ddfefd;
                width: 80px;
                height: 100%;
                border-radius: 50%;
                filter: blur(5px);
                right: 0;
                z-index: -1;
                position: absolute;
            }
        }
    }

    ul {
        align-items: center;
        justify-content: center;

        li {
            display: flex;

            svg {
                width: 35px;
                height: 35px;
                cursor: pointer;
                transition: 0.2s;

                &:hover {
                    transform: scale(1.3);
                }

                path {
                    fill: $black;
                }

                &.phone {
                    width: 25px;
                }
            }

            button {
                font-size: 20px;
                cursor: pointer;
                background-color: $blue;
                word-break: keep-all;
                text-wrap: nowrap;

                &:hover {
                    font-weight: 700;
                    transform: scale(1.1);
                }
            }
        }

        @include for-tablet-layout {
            transition: 0.3s;
            position: absolute;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            left: -100%;
            display: none;
            top: 0;
            background-color: $white;
            height: 100vh;
            width: 100%;
            gap: 30px;

            &.active {
                display: flex;

                left: 0;
            }

            button {
                font-size: $h2 !important;
            }
        }
    }

    .waze {
        position: relative;

        .waze-options {
            transition: 0.3s;
            visibility: hidden;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 35px;
            background-color: $white;
            width: max-content;
            color: $black;
            padding: 5px;
            border: 2px $black solid;
            border-radius: $br;
            right: -100%;
            opacity: 0;

            &.active {
                visibility: visible;
                opacity: 1;
                top: 30px;
            }

            button {
                box-shadow: none;
                background-color: transparent;
                color: black;
                font-size: $smaller;

                &:hover {
                    text-decoration: underline $black 2px;
                }
            }
        }
    }

    a {
        display: flex;
    }
}

.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    display: none;
    z-index: 10;

    @include for-tablet-layout {
        display: block;
    }

    * {
        pointer-events: none;
    }
}

.hamburger:hover {
    opacity: 0.7;
}

.hamburger.active:hover {
    opacity: 0.7;
}

.hamburger.active .hamburger-inner,
.hamburger.active .hamburger-inner::before,
.hamburger.active .hamburger-inner::after {
    background-color: #000;
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--spring .hamburger-inner {
    top: 2px;
    transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.active .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important;
}

.hamburger--spring.active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--spring-r .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}