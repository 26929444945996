.carousel {
    // min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    direction: ltr;
    padding: $padding;
    margin: 100px 0;

    .splide {
        z-index: 0;

        .splide__slide {
            img {
                width: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
                border-radius: $br;
                max-width: 400px;
            }
        }

        .splide__arrows {
            button {
                transform: translateY(-50%);
                width: fit-content;
                padding: 5px 10px;

            }

            @include for-mobile-layout {

                .splide__arrow--next,
                .splide__arrow--prev {
                    display: none;
                }

            }
        }

        .splide__pagination {
            button {
                height: auto;
                width: auto;
                padding: 5px 10px;
            }
        }
    }
}