.believe {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .leaf {
        max-width: 200px;
        z-index: 0;
        &.top {
            transform: rotate(220deg);
            margin-top: -60px;
        }

        &.bot {
            margin-bottom: -60px;
            transform: rotate(40deg);
            align-self: flex-end;
        }
    }

    p {
        font-size: $p;
        word-spacing: 3px;
        font-family: OpenSans;
        font-weight: 500;
        text-align: center;
        background-color: $white;
    }
}