@font-face {
    font-family: Rubik;
    src: url('../../fonts/Rubik/Rubik-Regular.ttf');
}

@font-face {
    font-family: Tinos;
    src: url('../../fonts/Tinos/Tinos-Regular.ttf');
}

@font-face {
    font-family: Tinos-B;
    src: url('../../fonts/Tinos/Tinos-Bold.ttf');
}

@font-face {
    font-family: OpenSans;
    src: url('../../fonts/OpenSans/OpenSans.ttf');
}

@font-face {
    font-family: Amatic;
    src: url('../../fonts/Amatic/Amatic.ttf');
}

@font-face {
    font-family: Amatic-B;
    src: url('../../fonts/Amatic/Amatic-B.ttf');
}