.hero-wrapper {
    background-color: $green;
    background-image: url('../../images/hero-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 70% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    height: 100%;
    width: 100%;

    .hero-data {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        max-width: min-content;
        background-color: $blue;
        padding: 10px 30px;
        border-radius: $br;
        box-shadow: 2px 2px 15px -1px rgba(0, 0, 0, 0.984);

        .hero-text {
            display: flex;
            flex-direction: column;
            width: max-content;
            gap: 10px;
            color: $white;
            text-shadow: 0px 0px 10px black;
            white-space: nowrap;

            h1 {
                line-height: 100%;
            }

            h3 {
                margin-right: -10px;
            }

            h3:nth-of-type(1) {
                letter-spacing: 10px;
            }

            h3:nth-of-type(2) {
                letter-spacing: 8px;
            }

            p{
                font-size: $p;
            }
            h4 {
                font-style: italic;
            }

            .hero-text-title {
                line-height: 40px;
            }


        }

        button {
            font-size: 30px;
            text-shadow: none;
        }
    }

}