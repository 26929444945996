.contact {
    margin: 100px 0;
    display: flex;
    justify-content: space-evenly;
    gap: 20px;

    @include for-tablet-layout {
        flex-direction: column;
        text-align: center;
    }

    .contact-data {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        line-height: 25px;

        div {
            padding: $padding;

        }

        p {
            font-family: OpenSans;
            color: $black;
        }

        .label {
            font-size: $small;
            color: $grey2;
            font-weight: 700;
        }

        .email {
            :nth-child(2) {
                font-weight: 500;
                color: $black;
                font-size: 20px;
            }

        }
    }

    .email {
        gap: 5px;

        p {
            color: $grey2;
            font-weight: 700;
            font-size: $small;
        }

        .email-form {
            height: 100%;
            justify-content: space-between;
            gap: 10px;
            // width: 100%;
            display: flex;

            textarea {
                height: 100%;

                @include for-tablet-layout {
                    height: 150px !important;
                }
            }

            button {
                // width: 100%;
                align-self: center;
            }

            input,
            textarea {
                border: 3px solid $blue;
                border-radius: $br;
                // box-shadow: inset 20px 20px 60px $blue;
                // background-color: $blue;
                padding: 5px 10px;
                font-size: 18px;
                font-family: OpenSans;
                resize: none;
                width: 100%;
                background-color: transparent;
                transition: 0.2s;

                &:hover {
                    border-color: $green;
                }

                &:active {
                    border-color: $green;
                    border: 3px solid $blue;
                }

                &:focus {
                    border-color: $green;
                    outline: none;
                }

                &::placeholder {
                    color: $grey2;
                }
            }



        }
    }

    .whatsapp {
        display: flex;
        font-family: OpenSans;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;

        @include for-tablet-layout {
            gap: 10px;
        }

        p {
            text-align: center;
            font-size: 24px;
        }

        a {
            display: flex;
            font-size: $h2;
            align-items: center;
            border-radius: $br;
            background: linear-gradient(145deg, #567885, #668e9e);
            box-shadow: 5px 5px 5px #51717e;
            padding: 10px 20px;
            color: $white;
            transition: 0.2s;

            &:active {
                transform: translate(5px, 5px);
                box-shadow: 0px 0px 5px #51717e;
            }

            &:hover {
                font-size: 43px;
            }
        }

        svg {
            max-width: 80px;
            align-self: center;
            fill: $green;
        }
    }


}